.navbar-nav > li.dropdown-toky.show {
  position: static;
}

.navbar-nav > li.dropdown-toky.show .dropdown-menu {
  display: table;
  border-radius: 0px;
  width: 100%;
  // text-align: center;
  left: 0;
  right: 0;
}

.dropdown-toky .dropdown-menu > li {
  display: table-cell;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
}

.dropdown-toky .dropdown-menu.show{
  border-top:5px solid $primary;
}

.dropdown-toky .dropdown-item{
  padding: 0.25rem 1.5rem 0.25rem 0;
}

.menu-cetegories{
  color: #3e70ad;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .dropdown-toky .dropdown-menu > li {
    display: block;
  }
}

.dropdown-lang.show .dropdown-menu a{
  width: auto;
}

.dropdown-lang .dropdown-menu {
  min-width: auto;
}