#filter-form{
    .form-control{
        border: 0;
        border-radius: 0;
        background-color: #EDF2F7;
        height: 50px;
    }
    .form-control:focus{
        box-shadow: none;
    }
    .btn{
        height: 50px;
        border-radius: 0;
        background-color: #edf2f7;
        border: #edf2f7;
        color: $dark-grey;
        text-align: right;
    }
}

.filter-form-shadow{
    box-shadow: 0px 52px 54px #00000029;
    background-color: #fff;
}