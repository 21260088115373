body{
	color: #62696B;
}

.header-dark.navbar-light .navbar-nav .nav-link{
    font-size: 24px;
    color: $grey;
    font-weight:700;
} 

.navbar-light .navbar-nav .nav-link{
    font-size: 24px;
    color: #ffffffb3;
    font-weight:700;
} 

.navbar-light .navbar-nav .nav-link:hover{
	color:#fff;
}

.bold{
	font-weight: 700;
}

.normal{
	font-weight: 400;
}

.light{
	font-weight: 300;
}

.italic-blue{
	font-style: italic;
	color: $blue;
}

.price-title{
	line-height: 1;
	font-size: 18px;;
}

.text{
	&-opa{
		color:#FFFFFFCC;
	}
	&-min{
		font-size: 10px;
	}
	&-shadow{
		text-shadow: 1px 1px 3px #102F5540;
	}
	&-orange{
		color: $orange;
	}
	&-dark-grey{
		color: $dark-grey;
	}
	&-red{
		color: $red;
	}
	&-mid-blue{
		color: $mid-blue;
	}
}

h1{
	font-size: 54px;
	font-weight: 700;
	line-height: 1.2;
	color: #353C3D;
}
h2{
	font-size: 44px;
	font-weight: 700;
	color: #353C3D;
	line-height: 1.2;
}
h3,.h3{
	font-size: 24px;
	font-weight: 300;
	line-height: 1.5;
}

h4{
	font-size: 20px;
	line-height: 1.5;
}

footer p{
	font-size: 12px;
}

.h3-special{
	font-size: 34px;
	color: #353C3D;
}

.section-title{
	padding: .3em 1em;
	font-size: 24px;
	letter-spacing: 2.4px;
	text-transform: uppercase;
	display: inline-block;
	font-weight: bold;
	font-style: italic;
	&-blue{
		color: $blue;
		background: rgba(62, 112, 173, .1) 0% 0% no-repeat padding-box;
	}
	&-blue-full{
		color: #fff;
		background: $blue;
	}
	&-green{
		color: $green;
		background: rgba(120, 195, 125, .1) 0% 0% no-repeat padding-box;
	}
}

.link{
	font-size: 20px;
	font-weight: 700;
	&-blue{
		color: $blue;
	}
	&-green{
		color: $green;
	}
}


form label{
	font-weight: 700;
}

form label span{
	font-weight: 400;
}

.costi-servizio h3{
	display: flex;
	justify-content: space-between;
	border-bottom: 1px dashed #707070;
    padding: 0.8rem 0;
    margin-bottom: 0;
	.heading{
		color: #353C3D;
	}
	.data{
		color: #EF5C3F;
	}
}

.features-platform{
	font-size: 24px;
	color: #353C3D;
	border-bottom: 2px dashed #353C3D;
	font-weight: bold;
}

@include media-breakpoint-down(md) { 
	h1{
		font-size: 36px;
	}
	h2{
		font-size: 29px;
	}
	.h3-special{
		font-size: 24px;
	}
	h3{
		font-size: 18px;
	}
	h4{
		font-size: 16px;
	}
	.features-platform{
		font-size: 18px;
	}
}

/* Typography */
.tc-h1{
	font-family: "Exo";
	color: $tc-primary;
}
.tc-h2{
	font-family: "Exo";
	font-size: 44px;
	color: $tc-grey;
}
.tc-h3{
	font-family: "Exo";
	font-size: 24px;
	color: $tc-grey;
}
.tc-p{
	font-family: "Exo";
	font-size: 16px;
	color: $tc-grey;
}
.text-grey{
	color: $tc-grey;
}