.btn{
	&-square {
	    border: 0;
	    border-radius: 0;
        padding: 1rem 1.5rem;
        font-size: 16px;
        font-weight: 700;
	}
	&-lg{
		font-size: 24px;
	}
	&-xl {
	    padding: 0.6em 3em;
	    font-weight: 700;
	}
	&-orange-inverse {
	    color: $orange;
	    background-color: #fff; 
	}
	&-dark-blue {
	    color: #fff;
	    background-color: $dark-blue; 
	    border-color: $dark-blue; 
	}
	&-dark-blue:hover {
	    color: #fff;
	    background-color: darken($dark-blue, 5%); 
	    border-color: $dark-blue; 
	}
	&-wmf{
		background: transparent linear-gradient(90deg, #D14238 0%, #F05C3F 100%) 0% 0% no-repeat padding-box;
		border-radius: 30px;
		color: #fff;
	}
	&-wmf2{
		background: transparent linear-gradient(90deg, #2661AB 0%, #4172B6 100%) 0% 0% no-repeat padding-box;
		border-radius: 30px;
		color: #fff;
	}
}

/* Margin */
.m-btn-header{
	margin-top: 4rem;
	margin-bottom: 4rem;
}
.p-section{
	padding-top: 4rem;
	padding-bottom: 4rem;
}
@media(max-width: 767px){
	.p-section{
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
	.m-btn-header{
		margin-top: 3rem;
		margin-bottom: 0rem;
	}
}

/* Btn */
.tc-btn-green{
	border-radius: 5px;
	background: $tc-green;
	color: #fff;
	font-family: "Exo";
	font-size: 24px;
	font-weight: bold;
	padding: 10px 20px;
	display: inline-block;
}
.tc-btn-green:hover{
	background: #649421;
	color: #fff;
	text-decoration: none;
}
.tc-btn-white{
	background-color: #fff;
	color: $tc-green;
	border-radius: 30px;
	font-family: "Exo";
	font-size: 24px;
	font-weight: bold;
	padding: 10px 50px;
	display: inline-block;
}
.tc-btn-white:hover{
	background: #649421;
	color: #fff;
	text-decoration: none;
}