// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'theme/background';
@import 'theme/buttons';
@import 'theme/elements';
@import 'theme/typography';
@import 'theme/menu';
@import 'theme/form';

// Owl carousel
@import 'owl/core';
@import 'owl/animate';
@import 'owl/autoheight';
@import 'owl/lazyload';
// @import 'owl/video';
@import 'owl/theme.default';