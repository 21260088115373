ul.social{
	display: inline-block;
	list-style: none;
	padding-left:0;
	li{
		display: inline;
		margin: 0 5px;
	}
}

footer .logo{
	max-width:160px;
}

footer ul{
	list-style: none;
	padding-left:0;
}

footer ul li a{
	color: #62696BB2;
}

.box-newsletter{
	background-color: $dark-blue;
	border-radius:10px;
	padding:2em 3em;
	position: relative;
	z-index: 10;
	margin-top: -230px;
	margin-bottom: 3em;
	
}

.bb-grey{
	border-bottom: 1px solid $light-grey;
}

.border-radius{
	border-radius: 20px;
}

.relative{
	position: relative;
}

.box-features{
	background-color: #fff;
	box-shadow: 0px 2px 20px #102F5526;
	border-radius: 10px;
	height: 100%;
}

.anchor-menu-api{
	list-style: none;
}

.anchor-menu-api li {
	background-color: #EDF2F7;	
	border-bottom: 1px dotted #3E70AD;
}
.anchor-menu-api li:last-child {
	background-color: #EDF2F7;	
	border-bottom: none;
}

.anchor-menu-api li a{
	color: $dark-grey;
	text-decoration: none;
}

.highlights{
	background-color: $dark-blue;
	padding: 0.5rem 1rem;
}
.owl-item img{
	height: 60px!important;
	width: auto!important;
	margin: auto;
}
.owl-item div{
	text-align: center;
}
/* Tab prezzi */
#price_tab.nav-tabs .nav-link.active, #price_tab.nav-tabs .nav-item.show .nav-link{
	border: none;
}
#price_tab{
	.nav-link.active{
		background-color: transparent;
		color: $orange;
		border-bottom: 5px solid $orange!important;
	}
	.nav-link{
		font-size: 24px;
		font-weight: 700;
		color: $dark-grey;
		margin: 0 1.5rem;
	}
	.nav-link:hover{
		border: none;
	}
}

@include media-breakpoint-down(sm) { 
	.box-newsletter{
		margin-top: -100px;		
	}
	.logo-td{
		max-width:200px;
	}#price_tab{
		.nav-link{
			margin: 0 .5rem;
		}
	}
}

/* Components */
.tc-media{
	@extend .px-4;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.13);
	-moz-box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.13);
	box-shadow: 0px 0px 10px 10px rgba(0,0,0,0.13);
	height: 100%;
}

@media(max-width: 1200px){
	.tc-media{
		display: block;
		
		img{
			display: block;
			margin: 0 auto 2rem!important;
		}
		.media-body{
			text-align: center;
		}
	}
}