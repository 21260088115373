body{
	background-color: #fff;
}

.bg{
	&-index{
		background-image: url('/assets/img/bg-index.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-business-partner{
		background-image: url('/assets/img/bg-business-partner.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-case-studies{
		background-image: url('/assets/img/bg-case-studies.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-propri-clienti{
		background-image: url('/assets/img/bg-propri-clienti.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-mail-pub{
		background-image: url('/assets/img/bg-mail-pub.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-display{
		background-image: url('/assets/img/bg-display.jpg');
		background-position: 60% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-campagne-email{
		background-image: url('/assets/img/bg-campagne-email.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-campagne-sms{
		background-image: url('/assets/img/bg-campagne-sms.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-invia-sms{
		background-image: url('/assets/img/bg-invia-sms.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-api-programmatori{
		background-image: url('/assets/img/bg-api-programmatori.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-ricezione-sms{
		background-image: url('/assets/img/bg-ricezione-sms.jpg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-servizi-plus-per-sms{
		background-image: url('/assets/img/bg-servizi-plus-per-sms.jpg');
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-invio-email-ai-propri-clienti{
		background-image: url('/assets/img/bg-invio-email-ai-propri-clienti.jpg');
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-blue{
		background-image: url('/assets/img/bg-blue.jpg');
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-white{
		background-image: url('/assets/img/bg-white.jpg');
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-white-down{
		background-image: url('/assets/img/bg-white-down.jpg');
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
	}
	&-white-color{
		background-color: #fff;
	}
	&-light-grey{
		background-color: $light-grey;
	}
	&-orange{
		background-color: $orange;
	}
	&-contact{
		background: transparent linear-gradient(161deg, #EDF2F7 0%, #EDF2F700 100%) 0% 0% no-repeat padding-box;
	}
	&-gradient{
		background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(237,242,247,1) 100%);
	}
}

@media(min-width: 1400px){
	.bg{
		&-wmf{
			background-image: url('/assets/img/wmf-header-desktop.jpg');
			background-position: right center;
			background-repeat: no-repeat;
			background-size: contain;
			min-height: 1200px;
		}
	}
	.bg{
		&-wmf2{
			background-image: url('/assets/img/wmf2-header-desktop.jpg');
			background-position: right center;
			background-repeat: no-repeat;
			background-size: contain;
			min-height: 1200px;
		}
	}
}
@media(min-width: 992px) and (max-width: 1199px) {
	.bg{
		&-wmf{
			background-image: url('/assets/img/wmf-header-desktop.jpg');
			background-position: right center;
			background-repeat: no-repeat;
			background-size: contain;
			min-height: 800px;
		}
	}
	.bg{
		&-wmf2{
			background-image: url('/assets/img/wmf2-header-desktop.jpg');
			background-position: right center;
			background-repeat: no-repeat;
			background-size: contain;
			min-height: 800px;
		}
	}
}

@media(min-width: 1200px) and (max-width: 1399px) {
	.bg{
		&-wmf{
			background-image: url('/assets/img/wmf-header-desktop.jpg');
			background-position: right center;
			background-repeat: no-repeat;
			background-size: contain;
			min-height: 1000px;
		}
	}
	.bg{
		&-wmf2{
			background-image: url('/assets/img/wmf2-header-desktop.jpg');
			background-position: right center;
			background-repeat: no-repeat;
			background-size: contain;
			min-height: 1000px;
		}
	}
}

// @media(max-width: 991px){
// 	&-wmf{
// 		background-image: url('/assets/img/wmf-header-mobile.jpg');
// 		background-position: right center;
// 		background-repeat: no-repeat;
// 		background-size: contain;
// 		min-height: 1200px;
// 	}
// }

.bg-title{
	letter-spacing: -0.24px;
	color: #FFFFFF;
	text-transform: uppercase;
	display: inline-block;
	padding: .3em 1em;
	width: 350px;
	font-weight: 700;
	&-red{
		background: transparent linear-gradient(90deg, $danger 0%, $danger 85%,#FFFFFF 100%) 0% 0% no-repeat padding-box;
	}
	&-green{
		background: transparent linear-gradient(90deg, $green 0%, $green 85%,#FFFFFF 100%) 0% 0% no-repeat padding-box;
	}
	&-blue{
		background: transparent linear-gradient(90deg, $primary 0%, $primary 85%,#FFFFFF 100%) 0% 0% no-repeat padding-box;
	}
}

/* Navbar */
.tc-nav-link{
	font-family: "Exo";
	font-size: 24px;
	font-weight: bold;
	color: #353c3d!important;
}
.tc-nav-link:hover{
	color: #4f585a!important;
}
.tc-nav-item{
	margin-left: 15px;
}
.tc-nav-link-btn{
	border-radius: 5px;
	background: $tc-primary;
	color: #fff!important;
}
.tc-nav-link-btn:hover{
	border-radius: 5px;
	background: #305b97;
	color: #fff!important;
}
@media(max-width: 1199px){
	.tc-nav-link{
		font-size: 20px;
	}
}
@media(max-width:991px){
	.tc-nav-item:last-child{
		margin-left: 0;
	}
	.tc-nav-item:last-child .tc-nav-link-btn{
		padding-left: 15px;
	}
}

/* Background */
.tc-bg-primary{
	background-color: $tc-primary;
}
.tc-bg-light-grey{
	background-color: #F4F4F4;
}
.tc-bg-green{
	background-color: $tc-green;
}

